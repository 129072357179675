.Vector_729 {
  animation: 0.26s linear 0s 1 normal forwards running Vector_729;
  transform: translateX(4px) translateY(43px);
}
@keyframes Vector_729 {
  0% {
    transform: translateX(4px) translateY(43px);
  }
  96.15% {
    transform: translateX(15px) translateY(36px);
  }
  100% {
    transform: translateX(15px) translateY(36px);
  }
}
.Frame_3_305 {
   animation: 0.26s linear 0.05s 1 normal forwards running Frame_3_305;
   opacity: 0;
   transform: translateX(29.27px) translateY(27.5px);
 }
@keyframes Frame_3_305 {
  0% {
    opacity: 0;
    transform: translateX(29.27px) translateY(27.5px);
  }
  19.23% {
    opacity: 0;
  }
  26.92% {
    transform: translateX(29.27px) translateY(27.5px);
  }
  100% {
    transform: translateX(41.27px) translateY(19.51px);
    opacity: 1;
  }
}