.reports-page {
  padding: 20px;
  height: 100%;
  .reports-table-wrapper {
    height: calc(100% - 30px);
    border-radius: 10px;
    margin-top: 10px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    .header {
      background-color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      border-radius: 10px;
      height: 60px;
      .title {
        color: #000;
        margin: 0;
        font-size: 22px;
      }
      .filters {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    .table-wrapper {
      height: calc(100% - 60px);
    }
    .preview-image {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
