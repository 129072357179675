$small_and_medium: 1024px;
$desktop: 1025px;
$main_blue: #3C50E0;
$main_deep_blue: #132539;
$Font_bold: Poppins-Bold;
$Font_medium: Poppins-Medium;

// For Desktop and Larger Device

@media screen and (min-width: $desktop) {

  .securityFeature_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    row-gap: 7vh;

    .small-cards {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

}


// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {

  .securityFeature_section {
    display: flex;
    align-items: center;
    margin-top: 10vh;
    flex-direction: column;
    width: 100%;
    row-gap: 5vh;
    margin-bottom: 5vh;


    .small-cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
      row-gap: 5vh;
    }
  }
}