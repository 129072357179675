@font-face {
    font-family: Poppins;
    src: local(Poppins), url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-style: normal;
}

.App {
    font-family: Poppins;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth;

}

/**{*/
/*    background-color: #0D1824;*/
/*    color: #E1E1E1;*/
/*}*/