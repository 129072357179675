$small_and_medium: 1024px;
$desktop: 1025px;
$main_deep_blue: #132539;

// For Desktop and Larger Device

@media screen and (min-width: $desktop) {
  .lowerFooter-main {
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    //height: 20vh;
    padding-bottom: 5vh;
    display: flex;
    justify-content: center;

    .footer-div {
      display: flex;
      width: 90%;
      align-items: center;
      justify-content: space-between;
      position: relative;
      top: 2vh;

      .footer-logo {
        display: flex;
        align-items: baseline;

        .flogo {
          max-width: 18vw;
        }

        .fios {
          max-width: 13vw;
          position: relative;
          top: 2vh;
          left: 1vw;
        }
      }

      .footer-right {
        display: flex;
        flex-direction: column;
        align-self: center;

        h1 {
          max-width: 20vw;
        }

        .footer-socialMedia {
          display: flex;
          justify-content: space-around;
          max-width: 20vw;
        }
      }
    }
  }
}


// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {

  .lowerFooter-main {
    background-color: #000000;
    color: #ffffff;
    width: 100%;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-div {
      display: flex;
      width: 90%;
      align-items: center;
      flex-direction: column;
      row-gap: 6vh;

      .footer-logo {
        display: flex;
        flex-direction: column;
        row-gap:1vh;
        align-items: center;
          .flogo {
            max-width: 70vw;
          }
        .fios{
          max-width: 30vw;

        }

      }

      .footer-mobile-divider {
        width: 80%;
        height: .2vh;
        background-color: $main_deep_blue;
      }

      .footer-right {
        display: flex;
        flex-direction: column;
        row-gap: 4vh;
        width: 100%;
        align-items: center;

        h1 {
          margin: 0;
          font-size: 4vw;
        }

        .footer-socialMedia {
          display: flex;
          width: 100%;
          justify-content: space-evenly;

        }
      }
    }
  }

}