.Layer_2_46 {
  animation: 0.5s linear 0s 1 normal forwards running Layer_2_46;
  transform: translateX(1.5px) translateY(54px);
  width: 0.1px;
  height: 30px;
}
@keyframes Layer_2_46 {
  0% {
    transform: translateX(1.5px) translateY(54px);
    width: 0.1px;
    height: 30px;
  }
  80% {
    transform: translateX(1.5px) translateY(1.5px);
    width: 33px;
    height: 33px;
  }
  100% {
    transform: translateX(1.5px) translateY(1.5px);
    width: 33px;
    height: 33px;
  }
}