@import "../../../../styles/styles";

.count-data {
  margin: 0 15px;
  border-bottom: 1px solid #E1E1E1;
  padding: 12px 0;
  margin-bottom:0.5rem;
  .title {
    color: #576574;
    font-family: $Poppins-Medium;
    font-size: 16px;
    margin: 0;
  }
  .value {
    color: #3D51DF;
    font-family: $Poppins-SemiBold;
    font-size: 24px;
    margin: 0;
  }
  &:last-child {
    border: none;
    margin-bottom: 0;
  }
}
