$main_deep_blue: #132539;
$small_and_medium: 1024px;
$desktop: 1025px;
$Font_regular: Poppins-Regular;

// For Desktop and Larger Device

@media screen and (min-width: $desktop) {

  .features-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5%;
    row-gap: 2vh;
    width: 27vw;
    height: 25vh;


    .features-img {
      max-width: 4vw;

      svg {
        width: 4vw;
      }

    }

    .features-heading {
      display: flex;
      flex-direction: column;

      .heading {
        font-size: 1.2vw;
        font-family: $Font_regular;
        margin: 0;
        padding: 0;
      }

      p {
        font-size: 0.8vw;
        width: 60%;
        align-self: center;
      }
    }
  }
}


// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {


  .features-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    text-align: center;
    margin-top: 5%;
    align-self: center;
    width: 80%;


    .features-img {
      max-width: 16vw;

      svg {
        width: 16vw;
      }
    }

    .features-heading {
      display: flex;
      flex-direction: column;

      .heading {
        font-size: 4vw;
        font-family: $Font_regular;
        margin: 0;
        padding: 0;
      }

      p {
        font-size: 3vw;
        width: 90%;
        align-self: center;
      }
    }
  }

}