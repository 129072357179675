$small_and_medium: 1024px;
$desktop: 1025px;
$main_deep_blue: #132539;
$Font_bold: Poppins-Bold;

// For Desktop and Larger Device

@media screen and (min-width: $desktop) {

  .small_card_section {
    display: flex;
    flex-direction: column;
    border-radius: 36px;
    color: $main_deep_blue;
    padding: 5% 5% 0 5%;
    width: 29vw;

    .small_card_heading {
      width: 70%;
      text-align: start;

      h1 {
        font-family: $Font_bold;
        font-size: 2.5vw;
        //width: 12vw;
        margin: 0;

      }

      p {
        font-size: 1.1vw;
        width: 20vw;
      }
    }

    .small_card_img {
      max-width: 20vw;
      position: relative;
      left: 5vw;
      display: flex;
      justify-content: end;

      img {
        width: 12vw;
      }
    }
  }

}


// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {

  .small_card_section {
    display: flex;
    flex-direction: column;
    width: 80%;
    border-radius: 36px;
    color: $main_deep_blue;
    background-color: #91b747;
    padding: 5vw 0 0 5vw;

    .small_card_heading {
      width: 70%;
      text-align: start;

      h1 {
        font-family: $Font_bold;
        font-size: 6vw;
        width: 65vw;
        margin: 0;
      }

      p {
        font-size: 3.3vw;
        width: 60vw;
      }
    }

    .small_card_img {
      max-width: 40vw;
      align-self: end;

      img {
        max-width: 40vw;
      }
    }
  }
}