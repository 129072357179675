.Group_440 {
  animation: 0.5s linear 0s 1 normal forwards running Group_440;
  transform: translateX(12.83px) translateY(59.64px);
}
@keyframes Group_440 {
  0% {
    transform: translateX(12.83px) translateY(59.64px);
  }
  40% {
    transform: translateX(12.83px) translateY(3.64px);
  }
  100% {
    transform: translateX(12.83px) translateY(3.64px);
  }
}
.Group_962 {
   animation: 0.5s linear 0s 1 normal forwards running Group_962;
   transform: translateX(0px) translateY(70.41px);
 }
@keyframes Group_962 {
  0% {
    transform: translateX(0px) translateY(70.41px);
  }
  80% {
    transform: translateX(0px) translateY(11.4px);
  }
  100% {
    transform: translateX(0px) translateY(11.4px);
  }
}
.Group_770 {
   animation: 0.5s linear 0s 1 normal forwards running Group_770;
   transform: translateX(32.4px) translateY(63.4px);
 }
@keyframes Group_770 {
  0% {
    transform: translateX(32.4px) translateY(63.4px);
  }
  80% {
    transform: translateX(32.4px) translateY(11.4px);
  }
  100% {
    transform: translateX(32.4px) translateY(11.4px);
  }
}
