$small_and_medium: 1024px;
$desktop: 1025px;
$gray: #dcdcdc;
$red: #d02030;

@mixin error {
  color: $red;
  font-size: 1.2vw;
}

@keyframes slide-right {
  0%, 100% {
    transform: translate(0);
  }

  80% {
    transform: translate(20%);
  }
}

// For Larger Device

@media screen and (min-width: $desktop) {
  .login-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    background-color: #fff;
    color: #000;
    width: 95%;
    gap: 3%;
    padding: 1% 0.5%;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.09);

    .formValidation {
      display: flex;
      flex-direction: column;
      width: 100%;

      .form-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-around;

        .login-reg {
          display: flex;
          width: 80%;
          outline: auto $gray;
          border-radius: 16px;

          .login-email {
            width: 49.5%;
            display: flex;
            flex-direction: column;

            .emailInput {
              display: flex;

              .userLogo {
                display: flex;
                width: 10%;
                max-height: 2vh;
                align-self: center;
              }

              .login-email-field {
                width: 90%;
                outline: none;
                font-size: 18px;
                text-align: start;
              }
            }


          }

          .login-input-divider {
            width: 0.2%;
            background-color: $gray;
          }

          .login-password {
            width: 49.5%;
            display: flex;
            flex-direction: column;
            margin-left: 1%;

            .passwordInput {
              display: flex;

              .passwordLogo {
                display: flex;
                width: 10%;
                max-height: 1.5vh;
                align-self: center;
              }

              .login-password-field {
                width: 90%;
                outline: none;
                font-size: 18px;
                text-align: start;
              }

              .eyeLogo {
                width: 5%;
                margin-right: 2%;
              }
            }


          }

          .error-mob {
            display: none;
          }

        }

        .login-button {
          outline-style: none;
          color: #fff;
          background-color: #3c50e0;
          border-radius: 16px;
          padding: 2vh 0;
          font-size: 18px;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5%;
          width: 15%;
          //border: 2px solid transparent;

          img {
            position: relative;
            left: 15%;
          }

          &:hover {
            //border: 2px solid #fff;
            background-color: #1232ef;
            transition: background-color .2s ease-out;


            img {
              animation: slide-right 1s ease-in-out;
              left: 18%;
            }
          }
        }
      }

      .err-div {

        display: flex;
        width: 80%;
        text-align: center;

        .error {
          width: 50%;
          @include error;
        }

        .psw {
          margin-left: auto;
        }
      }
    }


  }
}

// For Mobile and Tab

@media screen and (max-width: $small_and_medium) {
  .login-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background-color: #fff;
    color: #000;
    height: auto;
    row-gap: 15px;
    padding: 5%;

    .formValidation {
      display: contents;

      .form-wrapper {
        width: 100%;
        display: contents;

        .login-reg {
          display: flex;
          flex-direction: column;
          padding: 5px;
          outline: none;
          border-radius: 25px;
          row-gap: 15px;
          width: 100%;
          align-items: center;

          .login-email {
            width: 100%;
            display: flex;
            outline: auto $gray;
            border-radius: 12px;
            flex-direction: column;

            .emailInput {
              display: flex;

              .userLogo {
                margin-left: 6%;
              }

              .login-email-field {
                text-align: start;
                font-size: 18px;
                width: 100%;
                min-height: 7vh;
                outline: none;
              }
            }

            //.error {
            //  @include error;
            //  font-size: 3vw;
            //  align-self: center;
            //  position: initial;
            //}
          }

          .login-input-divider {
            display: none;
          }

          .login-password {
            width: 100%;
            display: flex;
            outline: auto $gray;
            border-radius: 12px;
            flex-direction: column;

            .passwordInput {
              display: flex;

              .passwordLogo {
                margin-left: 6%;
              }

              .login-password-field {
                width: 100%;
                text-align: start;
                font-size: 18px;
                min-height: 7vh;
                outline: none;
              }

              .eyeLogo {
                margin-right: 2%;
              }
            }

            //.error {
            //  @include error;
            //  font-size: 3vw;
            //  align-self: center;
            //  position: initial;
            //}
          }

          .error-mob {
            @include error;
            font-size: 3vw;
            align-self: center;
            position: initial;
          }
        }

        .login-button {
          width: 100%;
          outline-style: none;
          border-style: none;
          color: #fff;
          background: linear-gradient(264.19deg, #3c50e0 9.19%, #3c50e0 104.3%);
          border-radius: 10px;
          min-height: 7vh;
          font-size: 17px;
          font-weight: 600;
          width: 100%;
          column-gap: 5%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            position: relative;
            left: 15%;
          }

          &:hover {
            background-color: #1232ef;
            transition: background-color .2s ease-out;


            img {
              animation: slide-right 1s ease-in-out;
              left: 18%;
            }
          }
        }

      }

      .err-div {
        display: none;
      }
    }

  }
}
