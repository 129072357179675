@import "../../styles/styles";

.dashboard-layout-wrapper {
  width: 100%;
  transition: background-color 0.1s, color 0.1s;

  .dashboard-layout {
    display: flex;
    width: 100%;
    min-height: 100vh;
    min-height: 100svh;
    overflow: hidden;

    .dashboard-layout-sidebar {
      // border-right: 1px solid $gray;
      @media screen and (max-width: 768px) {
        border-right:none;

      }
    }

    .dashboard-layout-card {
      width: 100%;
      height: 100vh;
      flex: 1;
      overflow: auto;
      background-color: $db_BG;
    }
  }
}

.light-mode {
  background-color: #ffffff;
  color: #333333;
}

//Dark theme
.dark-mode {
  @include Dark-mode;
}
