$main_deep_blue: #132539;
$small_and_medium: 1024px;
$desktop: 1025px;
$Font_bold: Poppins-Bold;
$Font_medium: Poppins-Medium;


// For Desktop and Larger Device

@media screen and (min-width: $desktop) {


  .feature-main {
    display: grid;
    justify-content: center;
    color: $main_deep_blue;
  .features-heading{
    display: flex;
    justify-content: center;
    h1 {
     align-self: center;
     text-align: center;
     font-size: 4vw;
     font-family: $Font_bold;
     width: 60%;
  }
}

    .features-section {
      display: grid;
      row-gap: 10vh;
      justify-content: center;
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
    }

    }
  }




// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {

  .feature-main {
    display: flex;
    flex-direction: column;
    color: $main_deep_blue;
.features-heading{
  display: flex;
  justify-content: center;
    h1 {
      align-self: center;
      text-align: center;
      font-size: 4.5vw;
      font-family: $Font_bold;
      width: 88%;
    }
}
    .features-section {
      display: flex;
      flex-direction: column;
      row-gap: 5vh;
      justify-content: center;
      width: 100%;

    }
  }
}


