$small_and_medium: 1024px;
$desktop: 1025px;
$main_blue: #3C50E0;
$main_deep_blue: #132539;
$Font_bold: Poppins-Bold;
$Font_medium: Poppins-Medium;

// For Desktop and Larger Device

@media screen and (min-width: $desktop) {
  .upperFooter-section {
    justify-content: center;
    background-color: $main_blue;
    color: #ffffff;
    width: 100%;
    margin-top: 7vh;
    padding-top: 8vh;
    padding-bottom: 5vh;
    align-items: center;
    display: flex;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;

    .section-divs {
      display: flex;
      width: 90%;
      column-gap: 2vw;
      justify-content: space-around;

      .child {
        position: relative;
        top: 1vh;
      }
    }
  }

}


// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {

  .upperFooter-section {
    justify-content: center;
    background-color: $main_blue;
    color: #ffffff;
    width: 100%;
    margin-top: 7vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;

    .section-divs {
      display: flex;
      width: 90%;
      flex-direction: column;
      align-items: center;
      margin-bottom: 8vh;

      .child {
        position: relative;
        top: 1.6vh;
      }
    }
  }
}