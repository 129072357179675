@import "../../styles/styles";

@mixin layoutDesign {
  background-color: $light_bg_color;
  border-radius: 20px;
  padding: 2rem;
}

.analytics-page {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .analytics-header {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 25px;
  }

  .chart-layout {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    padding: 20px;
    gap: 20px;
    grid-template-rows: auto;

    .container {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      align-items: flex-start;
      justify-content: space-around;
      gap: 20px;
      padding: 0;
    }

    & > .container {
      //border-bottom: 1px solid #e1e1e1;
      //padding-bottom: 20px;

      & > .card-with-header,
      & > .chart-card {
        //border-left: 1px solid #e1e1e1;
        //padding-left: 20px;
      }
    }

    .chart-card {
      // box-shadow: 0 0 10px 1px #0000001c;
      //border-radius: 4px;
      height: 400px;
      padding: 20px 0;
      padding-right: 10px;

      &.donut-chart {
        height: unset;
        //aspect-ratio: 1;
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 2.5rem;

        &:last-child {
          border: none;
          margin-bottom: 0;
        }
      }

      &.line-chart {
        height: 400px;
        @include layoutDesign;
      }

      @media screen and (min-width: 768px) {
        &.line-chart {
          @include layoutDesign;
        }
        &.heat-map {
          @include layoutDesign;
          padding-bottom: 2rem;
        }
        &.horizontal-bar-chart {
          @include layoutDesign;
        }
      }

      &.heat-map {
        @include layoutDesign;
        padding-bottom: 4rem;
      }

      &.pie-chart,
      &.half-donut-chart,
      &.bar-chart,
      &.donut-chart,
      &.funnel-chart,
      &.html {
        @include layoutDesign;
      }

      &.horizontal-bar-chart {
        @include layoutDesign;
      }

      .head {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .title {
          font-size: 19px;
          font-weight: 700;
          color: #282561;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 20px;
          margin-top: 0;
        }

        .actions {
          display: flex;
          align-items: center;
        }
      }

      .card-error {
        .subtitle {
          font-size: 16px;
          margin: 10px auto 20px;
        }
      }

      .content {
        height: calc(100% - 50px);
        height: 100%;
      }

      .half {
        height: 350px;
      }

      .one-third {
        height: 300px;
      }

      .one-fourth {
        height: 300px;
      }

      .two-third {
        height: 300px;
      }

      .three-fourth {
        height: 300px;
      }
    }

    .card-with-header {
      .card-with-header-head {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .title {
          font-size: 19px;
          font-weight: 700;
          color: #282561;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 20px;
          text-align: center;
          //margin-bottom: 4.5rem;
        }

        .actions {
          display: flex;
          align-items: center;
        }
      }

      &.restricted-height {
        height: 400px;

        @media screen and (min-width: 768px) {
          .card-with-header-head {
            margin-bottom: 1rem;
          }
        }
      }

      &.restricted-height .content {
        height: calc(100% - 60px);
        overflow: auto;
        overflow: overlay;
        @include scrollBar;
      }

      .half {
        height: 350px;
      }

      .one-third {
        height: 300px;
      }

      .one-fourth {
        height: 300px;
      }

      .two-third {
        height: 300px;
      }

      .three-fourth {
        height: 300px;
      }
    }

    .tooltip {
      background-color: rgba($color: #000000, $alpha: 0.8);
      color: white;
      padding: 10px;
      border-radius: 5px;
    }

    .full {
      grid-column: span 12;
    }

    .half {
      grid-column: span 6;
    }

    .one-third {
      grid-column: span 4;
    }

    .one-fourth {
      grid-column: span 3;
      @include layoutDesign;
      height: 100%;
      overflow: hidden;
    }

    .two-third {
      grid-column: span 8;
    }

    .three-fourth {
      grid-column: span 9;
    }
  }
}

.fill {
  text-align: center;

  .title {
    font-size: 22px;
    font-weight: 600;
    margin: 10px auto;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .retry {
    margin-bottom: 100px;
  }
}

@import "../../styles/styles";

.userCountCard {
  display: flex;
  column-gap: 0.5vw;
  align-items: center;
  text-align: start;
  background-color: $light_bg_color;
  padding: 2rem;
  border-radius: 20px;
  max-height: 15vh;

  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 20px;
  }

  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    .userCountCard-left {
      background: rgba(61, 81, 223, 0.3);
    }
  }

  &:nth-child(2) {
    .userCountCard-left {
      background: rgba(55, 168, 126, 0.3);
    }
  }

  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    .userCountCard-left {
      background: #b9ecf3;
    }
  }

  .userCountCard-left {
    border-radius: 8px;

    img {
      padding: 1vw;
    }
  }

  .userCountCard-right {
    h1 {
      font-family: $Poppins-SemiBold;
      color: $main_deep_blue;
      font-size: 2vw;
      margin: 0;
    }

    p {
      font-family: $Poppins-Medium;
      color: $gray_dark;
      font-size: 1vw;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    column-gap: 1vw;
    .userCountCard-left {
      border-radius: 8px;
      background: rgba(61, 81, 223, 0.08);

      img {
        padding: 3vw;
      }
    }
    .userCountCard-right {
      h1 {
        font-family: $Poppins-SemiBold;
        color: $main_deep_blue;
        font-size: 5vw;
        margin: 0;
      }

      p {
        font-family: $Poppins-Medium;
        color: $gray_dark;
        font-size: 3vw;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .analytics-page {
    .chart-layout {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;

      .container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .card-with-header {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: none;

          .card-with-header-head {
            //margin-top: 5vh;
          }
        }
      }

      .full {
        width: 100%;
        //overflow-x: scroll;
      }

      .chart-card {
        padding: 0;

        .donut-chart {
          aspect-ratio: 0;
        }
      }

      .line-chart {
        width: 100%;
        overflow-y: hidden;
        .content {
          width: 200vw;
          overflow-x: scroll;
        }
      }

      .heat-map {
        overflow-y: hidden;
        .content {
          width: 200vw;
          overflow-x: scroll;
        }
      }

      .horizontal-bar-chart {
        overflow-y: hidden;
        //overflow-x: scroll;
        .content {
          width: 200vw;
          overflow-x: scroll;
        }
      }
    }
  }
}
