$small_and_medium: 1024px;
$desktop: 1025px;
$main_blue: #3C50E0;
$main_deep_blue: #132539;
$Font_bold: Poppins-Bold;
$Font_medium: Poppins-Medium;


// For Desktop and Larger Device

@media screen and (min-width: $desktop) {


  .main-div {
    display: flex;
    column-gap: 1.4vw;
    align-items: baseline;

    .imag-div {
      max-width: 2vw;

      img {
        width: 2vw;
      }

  }

    .heading-div {
      display: flex;
      flex-direction: column;

      h1 {
        margin: 0;
        font-size: 1.8vw;
        width: 12vw;
        text-align: start;
      }

      p {
        width: 13vw;
        text-align: start;
        font-size: 1vw;
      }
    }
  }

}

// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {
  .main-div {
    display: flex;
    column-gap: 4vw;
    margin-top: 10vh;
    align-items: baseline;
    text-align: start;

    .imag-div {
      max-width: 8vw;

      img {
        width: 8vw;
      }
    }

    .heading-div {
      display: flex;
      flex-direction: column;

      h1 {
        margin: 0;
        font-size: 7vw;
        width: 45vw;
      }

      p {
        width: 48vw;
      }
    }
  }
}