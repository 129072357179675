$small_and_medium: 1024px;
$desktop: 1025px;
$main_blue: #3C50E0;
$main_deep_blue: #132539;
$Font_bold: Poppins-Bold;
$Font_medium: Poppins-Medium;

// For Desktop and Larger Device

@media screen and (min-width: $desktop) {


  .login-input-field {
    width: 90%;
    outline: none;
    font-size: 18px;
    text-align: start;
    font-family:$Font_medium ;
    padding: 15px 10px;

  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
}


// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {
  .login-input-field {
    text-align: start;
    font-size: 18px;
    width: 100%;
    min-height: 7vh;
    outline: none;
    font-family:$Font_medium ;

  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
}


