@import "../../../../styles/styles";

.side-menu {
  background-color: #1C2DB0;
  height: 100vh;
  height: 100svh;
  width: 80px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  transition: width 0.5s;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    background-color: #1C2DB0;
    width: auto;
    position: absolute;
    z-index: 5;
    height: auto;
    .content {
      display: none;
    }
    .footer {
      display: none;
    }
    .head {
      background-color: $db_BG;
    }
  }

  &.expanded {
    width: 220px;

    @media screen and (max-width: 768px) {
      width: 80%;
      max-width: 450px;
      position: absolute;
      height: 100%;
      transition: all 1s ease;
      transform: translateX(0);
      .close-button {
        display: flex;
        justify-content: end;
        color: $light_bg_color;
        background-color: transparent;

        button {
          background-color: transparent;
          position: relative;
          top: 2vh;
        }

      }
      .head {
        background-color: transparent;

        .logo {
          width: 50%;
        }
      }
      .content {
        display: block;
      }
      .footer {
        display: block;
      }
    }
  }

  .close-button {
    display: none;
  }

  .head {
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;

    .logo {
      width: 80%;

    }

    svg {
      cursor: pointer;
      color: $light_bg_color;
      @media screen and (max-width: 768px) {
        color: $light_bg_color;
        background-color: $light_blue;
        border-radius: 50%;
        padding: 4px;
      }
    }
  }

  .MuiListItemButton-root {
    padding: 15px;
    padding-left: 28px;
    padding-right: 6px;
    justify-content: space-between;
    color: $light_bg_color;

    .MuiListItemIcon-root {
      min-width: 40px;
    }
  }

  .MuiCollapse-root {
    background-color: $main_deep_blue;

  }

  .content {
    flex: 1;
    overflow: hidden;
    margin-bottom: 1vh;

    svg {
      color: $light_bg_color;
    }

    .MuiListItemText-root {
      color: $light_bg_color;
    }

    .MuiCollapse-root {
      background-color: transparent;
      color: $main_deep_blue;

    }

    .Mui-selected {
      background-color: #3D51DF;

      svg {
        color: #FFFFFF;
      }

      .MuiListItemText-root {
        color: #FFFFFF;
      }

    }
  }

  .footer {
    color: $light_bg_color;
    position: relative;
    font-family: $Poppins-Medium;

    .darkmode-wrapper {
      // text-align: center;
      width: 100%;
      margin-bottom: 10px;
      padding-left: 28px;

      .MuiTypography-root {
        margin-left: 20px;
      }
    }

    svg {
      color: $light_bg_color;
    }
  }
}
