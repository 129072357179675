@import "../../../../styles/styles";

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: $db_BG;
  @media screen and (max-width: 768px) {
    padding: 1rem 1rem;
    align-items: end;
    .headerLeft {
      width: 100%;
      text-align: center;
    }
    }
    .profile-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #f0f0f0;
  }

  .headerLeft {
    h1 {
      font-size: 2.5vw;
      font-family: $Poppins-Bold;
      margin: 0;
    }
  }

  .headerRight {
    display: flex;
    column-gap: 0.8rem;
    align-items: center;

    .headerDate {
    }

    .headerProfile {
      cursor: pointer;
    }
  }
}

.rs-stack-item {
  font-family: $Poppins-Regular;
}



@media  screen and (max-width: 768px) {
  .header {
flex-direction: column;
    .headerLeft{
      h1 {
        font-size: 6vw;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
    .headerRight {
      .headerProfile {
        display: none;
      }
      }

    }
}
