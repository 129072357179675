@import "../../../../styles/styles";

.profile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .profile-img {
    display: flex;
    border-radius: 50%;
    border: 4px solid #ffffff;
    overflow: hidden;
    object-fit: contain;
    width: 120px;
    height: 120px;
    background: white;
  }
  .profile-title {
    font-family: $Poppins-Regular;
    font-size: 1rem;
    color: #576574;
    text-align: center;
  }
  &:hover {
    .profile-img {
      -webkit-box-shadow: 0 0 61px -10px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0 0 61px -10px rgba(0, 0, 0, 0.75);
      box-shadow: 0 0 61px -10px rgba(0, 0, 0, 0.75);
    }
    .profile-title {
      font-family: $Poppins-Bold;
    }
  }
}
