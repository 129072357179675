@import "../../styles/styles";




.homePage {
  font-family: $Poppins-Regular;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  scroll-behavior: smooth;
  overflow-y: hidden;
}


