.Union_172 {
  animation: 0.5s linear 0s 1 normal forwards running Union_172;
  transform: translateX(7.5px) translateY(-55px);
}
@keyframes Union_172 {
  0% {
    transform: translateX(7.5px) translateY(-55px);
  }
  54% {
    transform: translateX(7.5px) translateY(3px);
  }
  100% {
    transform: translateX(7.5px) translateY(3px);
  }
}
.Rectangle_1113_187 {
   animation: 0.5s linear 0.16s 1 normal forwards running Rectangle_1113_187;
   transform: translateX(60px) translateY(11px);
 }
@keyframes Rectangle_1113_187 {
  0% {
    transform: translateX(60px) translateY(11px);
  }
  32% {
    transform: translateX(60px) translateY(11px);
  }
  62% {
    transform: translateX(11px) translateY(11px);
  }
  100% {
    transform: translateX(11px) translateY(11px);
  }
}
.Rectangle_1114_56 {
   animation: 0.5s linear 0.15s 1 normal forwards running Rectangle_1114_56;
   transform: translateX(-48px) translateY(22px);
 }
@keyframes Rectangle_1114_56 {
  0% {
    transform: translateX(-48px) translateY(22px);
  }
  30% {
    transform: translateX(-48px) translateY(22px);
  }
  60% {
    transform: translateX(11px) translateY(22px);
  }
  100% {
    transform: translateX(11px) translateY(22px);
  }
}
.Rectangle_1115_971 {
   animation: 0.5s linear 0.16s 1 normal forwards running Rectangle_1115_971;
   transform: translateX(68px) translateY(33px);
 }
@keyframes Rectangle_1115_971 {
  0% {
    transform: translateX(68px) translateY(33px);
  }
  32% {
    transform: translateX(68px) translateY(33px);
  }
  62% {
    transform: translateX(11px) translateY(33px);
  }
  100% {
    transform: translateX(11px) translateY(33px);
  }
}
