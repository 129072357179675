@font-face {
  font-family: Poppins-Bold;
  src: local(Poppins-Bold),
    url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Poppins-Medium;
  src: local(Poppins-Medium),
    url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Poppins-Regular;
  src: local(Poppins-Regular),
    url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: Poppins-SemiBold;
  src: local(Poppins-SemiBold),
    url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-style: normal;
}

//Fonts :
$Poppins-Regular: Poppins-Regular; //400
$Poppins-Medium: Poppins-Medium; //500
$Poppins-SemiBold: Poppins-SemiBold; //600
$Poppins-Bold: Poppins-Bold; //700

//Colors :
$light_bg_color: #fff;
$db_BG: #f3f4fd;
$dark_div: #182439;
$dark_theme: #0d1321;
$main_deep_blue: #132539;
$dark_theme_div: #132539;
$light_blue: #3d51df;
$light_gray: #e8e8e8;
$gray: #e1e1e1;
$gray_dark: #576574;
$gray_l: #333333;
$green_10: #87cbb21a;
$green_20: #87cbb233;
$green_30: #87cbb24d;
$green_60: #37a87e99;
$green_40: #87cbb266;

//Breakpoints:
$small_and_medium: 1024px;
$desktop: 1025px;

// customize scrollbar:
@mixin scrollBar {
  &::-webkit-scrollbar-track {
    border: 1px solid transparent;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light_blue;
    border-radius: 5px;
  }
}

//dark theme :
@mixin Dark-mode {
  background-color: $dark_theme;
  color: $light_bg_color;

  .side-menu {
    background-color: $dark_div;

    &.expanded {
      @media screen and (max-width: 768px) {
        .close-button {
          color: $light_bg_color;
        }
        .head {
          background-color: $dark_div;
          svg {
            background-color: $dark_div;
            border-radius: 50%;
            padding: 4px;
          }
        }
      }
    }

    .head {
      svg {
        color: $light_bg_color;
        @media screen and (max-width: 768px) {
          background-color: $main_deep_blue;
        }
      }
      @media screen and (max-width: 768px) {
        background-color: $dark_theme;
      }
    }

    .content {
      svg {
        color: $light_bg_color;
      }
      .MuiListItemText-root {
        color: $light_bg_color;
      }
    }

    .MuiCollapse-root {
      background-color: $dark_theme;
      color: $light_bg_color;
    }
    .footer {
      svg {
        color: $light_bg_color;
      }
    }
  }

  .menu-item {
    color: $light_bg_color;

    &.active {
      background-color: $light_bg_color;
      color: $light_blue;

      .icon {
        color: $light_blue;
      }
    }
  }
  .footer {
    label {
      color: $light_bg_color;
    }
    .MuiListItemText-root {
      color: $light_bg_color;
    }
  }
  .header {
    background-color: $dark_theme;

    .headerDate {
      .rs-picker-toggle-textbox {
        background-color: $dark_theme_div !important;
      }
    }
  }
  .totalAndUniqueUsers {
    color: $light_bg_color;
  }
  .userCountCard {
    background-color: $dark_div;

    h1 {
      color: $light_bg_color;
    }

    .totalAndUniqueUsers-right {
      h1 {
        color: $light_bg_color;
      }

      p {
        color: #cacaca;
      }
    }
  }
  .chart-layout {
    .card-with-header {
      background-color: $dark_div;

      .card-with-header-head {
        .title {
          color: $light_bg_color;
        }
      }

      .content {
        .count-data {
          .value {
            color: $light_bg_color;
          }

          .title {
            color: $light_bg_color;
          }

          color: $light_bg_color;
        }
      }
    }
    .one-fourth {
      background-color: $dark_div !important;
    }

    .chart-card {
      .head {
        .title {
          color: $light_bg_color;
        }
      }

      &.line-chart {
        background-color: $dark_div;
      }

      &.heat-map {
        background-color: $dark_div;
      }

      &.pie-chart,
      &.half-donut-chart,
      &.bar-chart,
      &.donut-chart,
      &.funnel-chart,
      &.html,
      &.horizontal-bar-chart {
        background-color: $dark_div;
      }
    }
  }

  .dashboard-layout-card {
    background-color: $dark_theme !important;

    .reports-page {
      .reports-table-wrapper {
        box-shadow: 0 0 2px 0 rgba(253, 253, 253, 0.5);

        .header {
          .title {
            color: $light_bg_color;
          }
        }

        .table-wrapper {
          .rs-table {
          }
        }

        .rs-input .rs-input-xs {
          background-color: $main_deep_blue;
        }
      }
    }
  }

  .trafficAndDropOff-wrapper {
    .trafficAndDropOff {
      .trafficAndDropOff-right {
        h1 {
          color: $light_bg_color;
        }
      }
    }
  }
}
