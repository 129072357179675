.Vector_164 {
  animation: 0.27s linear 0s 1 normal forwards running Vector_164;
  transform: translateX(21.5px) translateY(12px) rotate(0deg);
  width: 63px;
  height: 60px;
}
@keyframes Vector_164 {
  0% {
    transform: translateX(21.5px) translateY(12px) rotate(0deg);
    width: 63px;
    height: 60px;
  }
  96.3% {
    transform: translateX(21.5px) translateY(23px) rotate(-360deg);
  }
  100% {
    transform: translateX(21.5px) translateY(23px) rotate(-360deg);
    width: 40px;
    height: 40px;
  }
}