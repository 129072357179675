@import "../../../../styles/styles";

.app-selection-page {
  display: flex;
  flex-direction: column;
  background: url("../../../../assets/oth/profile-bg.webp") no-repeat center;
  background-size: cover;
  backdrop-filter: blur(10px);
  width: 100%;
  min-height: 100vh;
  //justify-content: center;
  align-items: center;
  .hero_logo {
    width: 100%;
    padding-left: 5%;
    padding-top: 3%;
    justify-self: left;
    img {
      width: 16rem;
    }
  }
  .profileSection {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .heading-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 40px auto;
      max-width: 900px;
      width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .profileSection-heading {
        font-family: $Poppins-Bold;
        color: $main_deep_blue;
        font-size: 3rem;

        @media screen and (max-width: 768px) {
          font-size: 2rem;
        }
      }
      .search-input {
        width: 50%;
        margin: 0;
        background: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #888888;
        color: #000;

        @media screen and (max-width: 768px) {
          width: 90%;
          margin: 20px auto;
          padding: 10px;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    .profiles {
      display: grid;
      gap: 1rem;
      justify-content: center;
      width: 90%;
      grid-template-columns: repeat(2, 1fr);
      // max-width: 1000px;
      overflow: auto;
      @media screen and (min-width: 767px) {
        grid-template-columns: repeat(5, 1fr);
      }
      @media screen and (min-width: 850px) {
        grid-template-columns: repeat(6, 1fr);
      }
      @media screen and (min-width: 1000px) {
        grid-template-columns: repeat(7, 1fr);
      }
      @media screen and (min-width: 1350px) {
        grid-template-columns: repeat(8, 1fr);
      }
      @media screen and (min-width: 1600px) {
        grid-template-columns: repeat(9, 1fr);
      }
      @media screen and (min-width: 1800px) {
        grid-template-columns: repeat(10, 1fr);
      }
    }
  }
}
