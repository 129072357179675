@import "../../../../styles/styles";

.css-6hp17o-MuiList-root-MuiMenu-list{

    li{
      border-radius: 8px;
      font-size: 1vw;
      font-family: $Poppins-Regular;
      text-align: start;
      margin:.5vh .5vw ;

      &:hover {
        background-color: $light_blue;
        color: #ffffff;
      }
    }


}