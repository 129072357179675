.Vector_347 {
  animation: 0.5s linear 0s 1 normal forwards running Vector_347;
  transform: translateX(-24px) translateY(0px);
  width: 24px;
}
@keyframes Vector_347 {
  0% {
    transform: translateX(-24px) translateY(0px);
    width: 24px;
  }
  40% {
    transform: translateX(0px) translateY(0px);
  }
  80% {
    width: 24px;
  }
  100% {
    width: 24px;
    transform: translateX(0px) translateY(0px);
  }
}
.Vector_936 {
   animation: 0.5s linear 0s 1 normal forwards running Vector_936;
   transform: translateX(-36px) translateY(11.48px);
 }
@keyframes Vector_936 {
  0% {
    transform: translateX(-36px) translateY(11.48px);
  }
  60% {
    transform: translateX(2px) translateY(11.48px);
  }
  80% {
    transform: translateX(0px) translateY(11.48px);
  }
  100% {
    transform: translateX(0px) translateY(11.48px);
  }
}
.Vector_407 {
   animation: 0.5s linear 0s 1 normal forwards running Vector_407;
   transform: translateX(-12px) translateY(22.96px);
 }
@keyframes Vector_407 {
  0% {
    transform: translateX(-12px) translateY(22.96px);
  }
  80% {
    transform: translateX(0px) translateY(22.96px);
  }
  100% {
    transform: translateX(0px) translateY(22.96px);
  }
}