@import "../../../../styles/styles";

$small_and_medium: 1024px;
$desktop: 1025px;
$main_blue: #3C50E0;
$main_deep_blue: #132539;
$Font_bold: Poppins-Bold;
$Font_medium: Poppins-Medium;

// For Desktop and Larger Device

@media screen and (min-width: $desktop) {

  .hero_section {
    display: flex;
    background: linear-gradient(180deg, #FFE5EF 0%, #FFE9D7 59.9%, #FFE9D7 59.91%, rgba(255, 234, 209, 0.50) 85.52%, rgba(255, 234, 209, 0.00) 97.4%, #FFF 97.41%);
    width: 100%;
    height: auto;
    justify-content: center;


    .hero_div {
      display: flex;
      flex-direction: column;
      margin-top: 4%;
      width: 90%;

      .hero_logo {
        max-width: 17vw;
        height: 2.7vh;
        position: relative;
        bottom: 3vh;

        img {
          width: 16vw;
        }
      }

      .hero_main {
        display: flex;
        width: 100%;

        .hero_heading {
          width: 50%;
          color: $main_deep_blue;
          text-align: start;

          h1 {

            font-size: 4vw;
            font-weight: 700;
            font-family: $Font_bold;
            margin-bottom: 0;
            margin-top: 2vh;

            span {
              color: $main_blue;
            }
          }

          p {
            font-size: 1.5vw;
            font-family: $Font_medium;
          }
        }

        .hero_img {
          width: 50%;
          display: flex;
          justify-content: end;

          .hero-lottie {
            position: relative;
            left: 4.5vw;
            bottom: 8vh;
          }

        }

      }

      .login {
        position: relative;
        bottom: 15%;
        display: flex;
        justify-content: center;
      }

      .secure_login-wrapper {
        width: 100%;
        position: relative;
        bottom: 13%;
        display: flex;
        justify-content: center;
        column-gap: 5px;

        .secure_login {

          font-family: $Poppins-SemiBold;
          font-size: 14px;
          max-width: 50%;
          margin: 0;
          color: #268763;
        }
      }
    }


  }
}


// FOr Mobile and Tab

@media screen and (max-width: $small_and_medium) {

  .hero_section {
    display: flex;
    background: linear-gradient(164.67deg, rgba(255, 230, 239, 1) 10.89%, rgba(255, 234, 209, 1) 72.82%);
    width: 100%;
    height: auto;
    justify-content: center;


    .hero_div {
      display: flex;
      flex-direction: column;
      margin-top: 10%;
      width: 90%;

      .hero_logo {
        max-width: 46vw;

        img {
          width: 46vw;
        }
      }

      .hero_main {
        display: flex;
        width: 100%;
        margin-top: 10%;

        .hero_heading {
          width: 100%;
          color: $main_deep_blue;
          text-align: start;

          h1 {

            font-size: 9.5vw;
            font-family: $Font_bold;


            span {
              color: $main_blue;
            }
          }

          p {
            font-size: 5vw;
            font-family: $Font_medium;
          }
        }

        .hero_img {
          display: none;

        }

      }

      .login {
        margin-top: 8%;
        position: relative;
        bottom: 4%;
      }

      .secure_login-wrapper {
        width: 100%;
        position: relative;
        bottom: 2%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        column-gap: 5px;

        .secure_login {
          font-family: $Poppins-SemiBold;
          font-size: 14px;
          margin: 0;
          color: #268763;


        }
      }

    }
  }


}